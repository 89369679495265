export default [
  {
    header: 'Customer Data',
    action: 'read',
    resource: 'Customer',
  },
  {
    title: 'Redeem Management',
    route: 'customer-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Customer',
  },
]
