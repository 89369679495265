export default [
  {
    header: 'Backend User Management',
  },
  {
    title: 'User Management',
    route: 'users-list',
    icon: 'UsersIcon',
    // acl: {
    action: 'read',
    resource: 'AuthUser',
    // },
  },
]
