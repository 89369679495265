export default [
  {
    header: 'Report Management',
    action: 'read',
    resource: 'Report',
  },
  {
    title: 'Client Redeem Report',
    route: 'customer-report',
    icon: 'SearchIcon',
    // acl: {
    action: 'read',
    resource: 'RedeemReport',
    // },
  },
  // {
  //   title: 'Monitor Report',
  //   route: 'monitor-report',
  //   icon: 'SearchIcon',
  //   // acl: {
  //   // action: 'read',
  //   // resource: 'ACL',
  //   // },
  // },
  {
    title: 'Order Scan Report',
    route: 'delivery-report',
    icon: 'SearchIcon',
    // acl: {
    action: 'read',
    resource: 'OrderReport',
    // },
  },
  {
    title: 'Log Report',
    route: 'log-report',
    icon: 'SearchIcon',
    // acl: {
    action: 'read',
    resource: 'LogReport',
    // },
  },
]
